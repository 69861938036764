import $ from 'jquery'
import { SectionManager } from '../../common/section_manager'
import { CountdownSection } from '../countdown'
import './index.scss'

class HeroSection extends CountdownSection {
  static initClass() {
    this.type = 'hero'
  }
}
HeroSection.initClass()

$(function () {
  new SectionManager([HeroSection])
})
